<template>
  <button-back @click="onBackClick" />

  <form-input-toggle
    class="app-header__mode-toggle"
    :value="isGameDemo"
    name="mode-toggle"
    :disabled="!gameInfo?.isDemoMode"
    @change="changeGameMode"
  />

  <div class="items">
    <template v-if="isLoggedIn">
      <form-input-deposit :isDemo="isGameDemo" />

      <button-favorite :gameId="gameInfo?.id as string" />
    </template>

    <div v-if="!isLoggedIn && !isTelegramUser" class="app-header__auth-buttons">
      <button-base type="gray" size="xs" @click="openModalSync(ModalName.SIGN_IN)">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'header.loginButton') }}
      </button-base>

      <button-base type="green" size="xs" @click="openModalSync(ModalName.SIGN_UP)">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'header.registerButton') }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ModalName } from '@skeleton/consts/modals';

  const { openModalSync } = useModalStore();
  const { isLoggedIn, isTelegramUser } = storeToRefs(useProfileStore());
  const { getGameView } = storeToRefs(useIsGamePlayed());
  const { changeGameView } = useIsGamePlayed();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());

  const { getContent } = useProjectMethods();

  const { getGamesInfo } = useCoreGamesApi();

  const router = useRouter();
  const route = useRoute();
  const { params } = route;

  const isGameDemo = computed(() => getGameView && route.query?.real !== 'true');

  const onBackClick = () => {
    changeGameView(false);
    router.back();
  };

  const changeGameMode = (): void => {
    useEvent('changeMobileGameMode');
  };

  const { data: gameInfo } = await useLazyAsyncData(`game${params.id}Info`, () => getGamesInfo(params.id as string));
</script>
