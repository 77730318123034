<template>
  <div :class="['main-layout', { 'drawer-minimize': isDrawerCompact }, { 'stop-transition': disabledTransition }]">
    <atomic-preloader v-show="isShowPreloader" />

    <layout-header-mobile v-if="isMobile" @logout="logOutUser" />

    <layout-header v-if="!isMobile" @logout="logOutUser" />

    <layout-drawer />

    <main class="app-main">
      <div class="main-layout__wrapper">
        <layout-skeleton v-show="isLoading" class="app-main__page" />

        <div v-show="!isLoading" class="app-main__page">
          <slot />
        </div>
      </div>
    </main>

    <div class="main-layout__footer">
      <div class="main-layout__wrapper main-layout__wrapper_no-radius">
        <layout-footer />
      </div>
    </div>

    <atomic-opacity-layer />

    <client-only>
      <transition name="fade" mode="out-in">
        <layout-game-return v-if="showReturnGame" :game="returnGame" />
      </transition>
    </client-only>

    <transition name="fade" mode="out-in">
      <nav-mob v-if="!isGamePage" />
    </transition>

    <client-only>
      <modal-cookie-request v-if="!isTelegramUser && !hasAcceptedCookie" />
      <bottom-sheet-mobile-game v-if="isMobile" />
      <modal-wallet-telegram-app v-if="isTelegramUser" />
      <modal-tg-news v-if="isTelegramUser" />
      <modal-sign-in v-if="!isTelegramUser" />
      <modal-wallet v-if="!isTelegramUser" />
    </client-only>

    <atomic-alert />

    <ModalsContainer />
  </div>
</template>

<script setup lang="ts">
  import { useCssVars } from '@skeleton/composables/useCssVars';

  import { ModalsContainer } from 'vue-final-modal';

  import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';
  import { ModalName } from '@skeleton/consts/modals';

  const {
    public: { apiBaseUrl },
  } = useRuntimeConfig();
  const route = useRoute();

  const globalStore = useGlobalStore();
  const { isShowPreloader } = storeToRefs(globalStore);
  const { locales } = globalStore;
  const { isMobile } = storeToRefs(useDeviceStore());

  const langLinks = locales.map(item => ({
    rel: 'alternate',
    hreflang: item.code,
    href: `${apiBaseUrl}/${item.code}${route.path}`,
  }));

  useHead({
    link: langLinks,
  });

  const loadingStore = useLoadingStore();
  const { isLoading } = storeToRefs(loadingStore);
  const layoutStore = useLayoutStore();
  const { updateAuthState } = useAuthorization();
  const { getFromStorage } = useBrowserStorage();
  const { checkOpenedModals, openModalSync } = useModalStore();
  const profileStore = useProfileStore();
  const { isLoggedIn, isTelegramUser } = storeToRefs(profileStore);

  const { showCookiePopup, isDrawerCompact, returnGame, isGamePage, isSportsbookPage } = storeToRefs(layoutStore);

  const { logOutUser } = profileStore;

  useCssVars();

  const timer = ref<ReturnType<typeof setTimeout>>();
  const disabledTransition = ref(true);
  const hasAcceptedCookie = ref(false);

  const checkDrawer = (): void => {
    if (isGamePage.value) return;
    const clientCompactDrawer = localStorage.getItem('IS_DRAWER_COMPACT');
    isDrawerCompact.value = clientCompactDrawer === 'true';
  };

  const showReturnGame = computed(
    () =>
      returnGame.value &&
      returnGame.value !== 'disabled' &&
      isMobile.value &&
      !isGamePage.value &&
      !isSportsbookPage.value
  );

  onBeforeMount(async () => {
    await checkOpenedModals();

    const storageReturnGame = sessionStorage.getItem('returnGame');
    if (storageReturnGame) returnGame.value = JSON.parse(storageReturnGame);

    if (!isLoggedIn.value) {
      const { data: registrationData } = await useApiAuthInstance('/api/player/commons/constants');
      updateAuthState('canAuthorizeWithEmail', registrationData.registration.email);
      updateAuthState('canAuthorizeWithPhone', registrationData.registration.phone);
      updateAuthState('requireEmailVerification', registrationData.auth.requireEmailVerification);
    }
  });

  onMounted(async () => {
    checkDrawer();
    disabledTransition.value = false;
    const cookieValue = getFromStorage('accept-cookie');

    const tgNews = localStorage.getItem('tg-news');

    if (!tgNews && isTelegramUser.value) {
      openModalSync(ModalName.TG_NEWS);
    }

    if (!cookieValue) {
      timer.value = setTimeout(() => {
        showCookiePopup.value = true;
      }, 1500);
    }
  });

  watch(
    () => route.fullPath,
    () => {
      checkOpenedModals();
    }
  );

  watch(
    () => route.path,
    () => {
      scrollBodyToTop();
    }
  );

  watchEffect(() => {
    hasAcceptedCookie.value = !!getFromStorage('acceptCookie');
  });

  onBeforeUnmount(() => {
    if (timer.value) clearTimeout(timer.value);
  });
</script>
