<template>
  <atomic-logo />

  <div class="items">
    <template v-if="isLoggedIn">
      <form-input-deposit />

      <nuxt-link :to="localizePath('/profile/info')" @click="handleChangeMobileMenu">
        <atomic-avatar />
      </nuxt-link>
    </template>

    <div v-if="!isLoggedIn && !isTelegramUser" class="app-header__auth-buttons">
      <button-base type="gray" size="xs" @click="openModalSync(ModalName.SIGN_IN)">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'header.loginButton') }}
      </button-base>

      <button-base type="green" size="xs" @click="openModalSync(ModalName.SIGN_UP)">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'header.registerButton') }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  import { ModalName } from '@skeleton/consts/modals';

  const { openModalSync } = useModalStore();

  const profileStore = useProfileStore();
  const { isLoggedIn, isTelegramUser } = storeToRefs(profileStore);

  const { localizePath } = useProjectMethods();

  const globalStore = useGlobalStore();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);

  const { getContent } = useProjectMethods();
  const { taptic } = useHaptics();

  const { changeMobileMenu } = useIsMobileMenuOpen();

  const handleChangeMobileMenu = () => {
    taptic('soft');
    changeMobileMenu(true);
  };
</script>
