<template>
  <div class="drawer" :class="{ 'is-compact': isDrawerCompact }">
    <div class="content">
      <cta-menu
        v-if="layoutData?.siteSidebar?.ctaMenu?.isShow"
        :items="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.ctaMenu.items')"
      />

      <!--      Games-->

      <atomic-menu-category
        :items="gamesMenu"
        :label="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.gamesMenu.label')"
        :isDrawerCompact="isDrawerCompact"
        icon="games-active"
      />

      <atomic-cta-menu-item
        v-bind="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.topMenu.integratedElement')"
      />

      <!-- Sports -->

      <atomic-menu-category
        :items="sportsMenu.items"
        :label="sportsMenu.label"
        :isDrawerCompact="isDrawerCompact"
        icon="sport-active"
      />

      <atomic-divider class="drawer__divider" />

      <!--      Bonuses-->

      <atomic-menu-category
        :items="bonusesMenu.items"
        :label="bonusesMenu.label"
        :isDrawerCompact="isDrawerCompact"
        :hiddenDropdown="isDrawerCompact"
        icon="bonus-active"
      >
        <client-only>
          <template #activator v-if="activeBonusesAndFreeSpins">
            <span class="bonus-count">
              {{ activeBonusesAndFreeSpins }}
            </span>
          </template>
        </client-only>
      </atomic-menu-category>

      <!--      VIP-->

      <atomic-link
        :class="['become-vip', { 'become-vip_compact': isDrawerCompact }]"
        :to="localizePath('/static/vip_club')"
      >
        <div class="become-vip__wrapper">
          <atomic-icon id="vip" />
          <span class="become-vip__label">
            {{ getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.topMenu.integratedElementVip.label') }}
          </span>
        </div>
        <atomic-image class="become-vip__bg-img" src="/img/diamond.png" />
      </atomic-link>

      <atomic-divider class="drawer__divider" />

      <!--     Telegram -->

      <template v-if="!isTelegramUser">
        <atomic-link :class="['telegram-bot', { 'telegram-bot_compact': isDrawerCompact }]" :to="botURL" target-blank>
          <atomic-icon id="telegram" />
          <span class="telegram-bot__label"> Telegram Bot </span>
        </atomic-link>

        <atomic-divider class="drawer__divider" />
      </template>

      <atomic-theme-switcher :is-drawer-compact="isDrawerCompact" />

      <atomic-divider class="drawer__divider" />

      <nav-static :items="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.sidebarFooterMenu')" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { buildMenu, type IMenuLink } from '@skeleton/utils/menuBuilder';
  import { GAMES_MENU } from '@skeleton/consts/menus/site-sidebar';

  const globalStore = useGlobalStore();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);
  const { getContent, localizePath } = useProjectMethods();

  const layoutStore = useLayoutStore();
  const { isDrawerCompact } = storeToRefs(layoutStore);
  const bonusStore = useBonusStore();
  const { activePlayerBonuses, activePlayerFreeSpins } = storeToRefs(bonusStore);

  const activeBonusesAndFreeSpins = computed(() => {
    return (activePlayerBonuses.value?.length || 0) + (activePlayerFreeSpins.value?.length || 0);
  });

  const profileStore = useProfileStore();
  const { isTelegramUser } = storeToRefs(profileStore);

  const {
    public: { botURL },
  } = useRuntimeConfig();

  const gamesMenu = computed((): IMenuLink[] => {
    return buildMenu(GAMES_MENU, layoutData.value, defaultLocaleLayoutData.value);
  });

  const sportsMenu = computed(() => {
    return getContent(layoutData.value, defaultLocaleLayoutData.value, 'siteSidebar.sportsMenu');
  });

  const bonusesMenu = computed(() => {
    return getContent(layoutData.value, defaultLocaleLayoutData.value, 'siteSidebar.bonusesMenu');
  });
</script>

<style src="~/assets/styles/components/layout/drawer.scss" lang="scss" />
